import React, { useEffect, useState } from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import '../css/landing.css';
import '../css/pages.css'
import '@mantine/core/styles.css';
import '@mantine/notifications/styles.css';
import '@mantine/dates/styles.css';
let linkTags = []
const IsolateContent = () => {
   
    return (
        <>
            <Outlet />
        </>
    );
};


export default IsolateContent;